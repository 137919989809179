<template>
  <div class="page_container">
    <div>
      <div class="code_input">
        <div class="input_wrapper">
          <input
            @keyup.enter="search"
            type="text"
            class="form-control"
            id="search-input"
            name="keyword"
            :placeholder="$t('searchForGamesLeaguesEvents')"
            :aria-label="$t('searchForGamesLeaguesEvents')"
            v-model="to_search"
            style="height: 54px"
          />
        </div>
        <ChopbetButton variant="primary" @click="search" :size="small">{{
          $t("search")
        }}</ChopbetButton>
      </div>
      <div class="container">
        <div>
          <div v-if="keywords.length < 1">
            <NoData
              :title="$t('searchForGames')"
              :description="$t('searchGames')"
            />
          </div>
        </div>
      </div>

      <Games
        v-bind:search="keywords"
        v-bind:searchable="searchable"
        v-on:search-results="handleSearchResults"
      >
      </Games>
    </div>
  </div>
</template>

<script>
// import BottomNavigation from './BottomNavigation'
import Games from "../../components/Games.vue";
// import ChopbetButton from "./ui/ChopbetButton.vue";
// import NoData from "./ui/NoData.vue";
import ChopbetButton from "../../components/ui/ChopbetButton.vue";
import NoData from "../../components/ui/NoData.vue";

export default {
  name: "Search",
  components: {
    Games,
    ChopbetButton,
    NoData,
  },
  data: function () {
    return {
      searchable: true,
      to_search: "",
      keywords: "",
      sub_page: "",
      results: "",
      busy: false,
      no_results: false,
      loading: false,
    };
  },
  mounted() {
    this.$store.dispatch("setCurrentPage", "search");
    this.getBonus();
    this.$store.dispatch("resetAllGames");
  },
  computed: {
    betslip_count: function () {
      return this.$store.state.betslip.total;
    },
    current_sub_page: function () {
      return this.$store.state.current_sub_page;
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
    shouldDisplayTrivia() {
      const now = new Date();
      const startDate = new Date("2024-05-05T13:06:00");
      const endDate = new Date("2024-05-05T16:30:00");
      return now >= startDate && now <= endDate;
    },
  },
  methods: {
    handleSearchResults(results) {
      this.results = results.length > 0;
      this.no_results = results.length === 0;
    },
    getKey: function (fixture, index) {
      if (Array.isArray(fixture)) {
        var currentFixture = fixture[0];
      } else {
        currentFixture = fixture;
      }

      var prefix =
        currentFixture === undefined || currentFixture.match_id === undefined
          ? index
          : currentFixture.match_id;
      return Math.random()
        .toString(10)
        .replace("0.", "fixture-id-" + prefix + "-");
    },

    setSubPage: function (page) {
      this.$store.dispatch("setCurrentSubPage", page);
      this.sub_page = page;
    },
    geSubPageActiveClass: function (subpage) {
      return subpage === this.sub_page ? "active" : "";
    },
    setSport: function (sportID) {
      this.$store.dispatch("setSportID", sportID);
    },
    search: function () {
      var vm = this;
      vm.keywords = vm.to_search;
      console.log("KEYWORD====>" + vm.to_search);
      // this.$refs.games.resetSearch();
    },
  },
};
</script>

<style scoped>
.code_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 8px;
}

.code_input button {
  width: 28%;
  margin-top: -8px;

  /* padding: 2px 0 !important;
  height: 47px; */
}

.input_wrapper {
  width: 70%;
}
.input_wrapper input {
  height: 47px;
  background-color: var(--background-color);
}
</style>
